import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { formatErrors, handleAPI } from "@redux/services/APIHandler";

export type BranchState = {
  loading: boolean;
  message: string | null;
  errors: string[];
  branches: any[];
};

const initialState: BranchState = {
  loading: false,
  message: null,
  errors: [],
  branches: [],
};

export const getAllBranches = handleAPI("branches/getAllBranches", "get", `global/branches`);
export const getBranchesByCountry = handleAPI("branches/getBranchesByCountry", "get", `global/branches/:id`);

export const branchesSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get All Branches
    builder.addCase(getAllBranches.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.branches = [];
      state.message = null;
    });
    builder.addCase(getAllBranches.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.branches = payload?.response ?? null;
    });
    builder.addCase(getAllBranches.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.branches = [];
      state.errors = formatErrors(payload);
    });

    // Get Branches By Country
    builder.addCase(getBranchesByCountry.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.branches = [];
      state.message = null;
    });
    builder.addCase(getBranchesByCountry.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.branches = payload?.response ?? null;
    });
    builder.addCase(getBranchesByCountry.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.branches = [];
      state.errors = formatErrors(payload);
    });
  },
});

//Export actions
export const {} = branchesSlice.actions;

// shortcuts
export const getBranch = (state: RootState) => state.dashboard.branch;

export default branchesSlice.reducer;
