import React, { useEffect } from "react";
import { ThemeContext } from "../app/ThemeContext";
import ThemeToggle from "../app/ThemeToggle";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { restartAuth, getProfile } from "../../redux/slices/authSlice";
import { setCurrentPage, setShowConfirmationDialog, toggleMobileMenu } from "../../redux/slices/globalSlice";

import { remitone, cms, environment, app_version } from "../../redux/endpoint";
import {
  IoChevronForwardCircleOutline,
  IoAppsSharp,
  IoHome,
  IoLocation,
  IoExit,
  IoCard,
  IoAddCircleSharp,
  IoArrowBackSharp,
  IoChevronForwardSharp,
} from "react-icons/io5";

import { motion, AnimatePresence } from "framer-motion";

import { Link, useNavigate, useParams } from "react-router-dom";
import { openInNewTab, _find } from "../../utils";
import { isMobile } from "../../utils/device";
import ConfirmationDialog from "@components/form-elements/ConfirmationDialog";
import { Search } from "@components/form-elements";

const getMenuParent = (id: string) => {
  let split = id.split(".");
  let result = split.slice(0, split.length - 1).join(".");
  return result;
};

const RenderMenuChildren = ({ children, menuItems, selectedMenu, setSelectedMenu }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentPage, mobileMenuOpen, useLocalhost } = useAppSelector((state: any) => state.global);

  const gotoPage = (page: string) => {
    navigate(page);
    if (mobileMenuOpen) dispatch(toggleMobileMenu());
  };

  return (
    <>
      <motion.span
        initial={{ x: "-50%", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
        onClick={() => setSelectedMenu(null)}
        className="flex flex-row items-center gap-4 p-2 my-2 text-black bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-300 hover:text-primary dark:text-gray-300 dark:bg-gray-700"
      >
        <IoArrowBackSharp className="font-bold text-1xl" />
        Main Menu
      </motion.span>
      <motion.span
        initial={{ x: "-50%", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
        onClick={() =>
          selectedMenu.parentId
            ? setSelectedMenu({
                parentId: getMenuParent(selectedMenu.parentId),
                items: _find(menuItems, "id", selectedMenu.parentId),
              })
            : setSelectedMenu(null)
        }
        className="flex flex-row items-center gap-4 p-2 font-bold rounded-lg cursor-pointer dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
      >
        <IoArrowBackSharp className="font-bold text-1xl" />
        {selectedMenu.items.title}
      </motion.span>
      <div className="flex flex-col gap-2">
        {/* <AnimatePresence> */}
        {children.map((child: any, key: number) => {
          return (
            <motion.span
              initial={{ x: "-50%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.2, ease: "easeOut" }}
              // exit={{ x: "50%", opacity: 0 }}
              key={key}
              className={`cursor-pointer transition-all ease-in-out delay-50 duration-100 pl-10 ${
                currentPage === child.id ? "font-medium text-secondary" : ""
              } hover:text-secondary hover:font-bold`}
              onClick={() =>
                child?.children
                  ? setSelectedMenu({ parentId: selectedMenu.items.id, items: child })
                  : child?.url
                  ? child.internal
                    ? gotoPage(child.url)
                    : openInNewTab(child.url)
                  : null
              }
            >
              {child.title}
            </motion.span>
          );
        })}
        {/* </AnimatePresence> */}
      </div>
    </>
  );
};

export default function Sidebar() {
  const { theme }: any = React.useContext(ThemeContext);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { profile, access_rights, branch } = useAppSelector((state: any) => state.auth);
  const { currentPage, mobileMenuOpen, useLocalhost } = useAppSelector((state: any) => state.global);
  const {
    loading: dashboardLoading,
    errors: dashboardErrors,
    message: dashboardMessage,
  } = useAppSelector((state) => state.dashboard);

  React.useEffect(() => {
    console.log("Profile has changed");
    dispatch(getProfile(""));
    if (!profile && !access_rights) dispatch(getProfile(""));
  }, []);

  const otherLinks = [
    {
      title: "Dashboard",
      internal: true,
      url: "/",
    },
    {
      title: "Remitters",
      children: [
        {
          title: "Create Remitter",
          url: "/remitters/create",
          internal: true,
        },
        {
          title: "Search Remitter",
          url: "/remitters/search",
          internal: true,
        },
      ],
    },
    {
      title: "Beneficiaries",
      children: [
        {
          title: "Create Beneficiary",
          url: "/beneficiaries/create",
          internal: true,
        },
        {
          title: "Search Beneficiary",
          url: "/beneficiaries/search",
          internal: true,
        },
      ],
    },
    {
      title: "Transactions",
      children: [
        {
          title: "Start Transaction",
          url: "/create-transaction",
          internal: true,
        },
        {
          title: "Search for a Transaction",
          url: "/transactions/search",
          internal: true,
        },
        {
          title: "Payout Transaction",
          url: "/transactions/payout",
          internal: true,
        },
        // {
        //   title: "Reports",
        //   url: "/local-transactions/reports",
        //   internal: true,
        // },
        {
          title: "Local Transactions",
          children: [{ title: "Clear Transaction", url: "/local-transactions/clear", internal: true }],
        },
        {
          title: "Outbound Transactions",
          children: [
            { title: "Pending", url: "/outbound-transactions/pending", internal: true },
            { title: "Errors", url: "/outbound-transactions/errors", internal: true },
            { title: "Processed", url: "/outbound-transactions/processed", internal: true },
          ],
        },
        {
          title: "Paytumi",
          url: "/transactions/paytumi",
          internal: true,
        },
        {
          title: "Senditoo",
          children: [
            { title: "Search Transaction", url: "/transactions/senditoo/search", internal: true },
            // { title: "View Reports", url: "/transactions/senditoo/reports", internal: true },
          ],
        },
        {
          title: "MamaMoney",
          children: [
            { title: "Search Transaction", url: "/transactions/mamamoney/search", internal: true },
            // { title: "View Reports", url: "/transactions/mamamoney/reports", internal: true },
          ],
        },
      ],
    },
    {
      title: "Reports",
      children: [
        {
          title: "MamaMoney Reports",
          url: "/transactions/mamamoney/reports",
          internal: true,
        },
        {
          title: "Senditoo Reports",
          url: "/transactions/senditoo/reports",
          internal: true,
        },
        {
          title: "Terrapay Reports",
          url: "/reports/transaction/terrapay",
          internal: true,
        },
        {
          title: "Agent Transaction",
          url: "/reports/transaction/agent",
          internal: true,
        },
        {
          title: "Remitter Transaction",
          url: "/reports/transaction/remitter",
          internal: true,
        },
        {
          title: "Beneficiary Transaction",
          url: "/reports/transaction/beneficiary",
          internal: true,
        },
        {
          title: "Paytumi Transaction",
          url: "/reports/transaction/paytumi",
          internal: true,
        },
      ],
    },
    {
      title: "Branches",
      url: "/branches",
      internal: true,
    },
    {
      title: "External Links",
      children: [
        { title: "RemitOne", url: remitone(), internal: false },
        { title: "CMS", url: cms(), internal: false },
        {
          title: "Paytumi",
          url: environment === "test" ? "https://test.paytumi.com/" : "https://paytumi.com/",
          internal: false,
        },
      ],
    },
  ];

  const saLinks = [
    {
      title: "Dashboard",
      internal: true,
      url: "/",
    },
    {
      title: "Remitters",
      children: [
        {
          title: "Create Remitter",
          url: "/remitters/create",
          internal: true,
        },
        {
          title: "Search Remitter",
          url: "/remitters/search",
          internal: true,
        },
      ],
    },
    {
      title: "Beneficiaries",
      children: [
        {
          title: "Create Beneficiary",
          url: "/beneficiaries/create",
          internal: true,
        },
        {
          title: "Search Beneficiary",
          url: "/beneficiaries/search",
          internal: true,
        },
      ],
    },
    {
      title: "Transactions",
      children: [
        {
          title: "Start Transaction",
          url: "/create-transaction",
          internal: true,
        },
        {
          title: "Search for a Transaction",
          url: "/transactions/search",
          internal: true,
        },
      ],
    },
    {
      title: "Reports",
      children: [
        {
          title: "Agent Transaction",
          url: "/reports/transaction/agent",
          internal: true,
        },
        {
          title: "Remitter Transaction",
          url: "/reports/transaction/remitter",
          internal: true,
        },
        {
          title: "Beneficiary Transaction",
          url: "/reports/transaction/beneficiary",
          internal: true,
        },
        {
          title: "Paytumi Transaction",
          url: "/reports/transaction/paytumi",
          internal: true,
        },
        {
          title: "Terrapay Reports",
          url: "/reports/transaction/terrapay",
          internal: true,
        },
      ],
    },
    {
      title: "Branches",
      url: "/branches",
      internal: true,
    },
    {
      title: "USSD Simulator",
      url: "/ussd",
      internal: true,
    },
    {
      title: "External Links",
      children: [
        { title: "RemitOne", url: remitone(), internal: false },
        { title: "CMS", url: cms(), internal: false },
      ],
    },
  ];

  // let topLinks: any = otherLinks;

  // const [topLinks, setTopLinks] = React.useState<any>(otherLinks)

  // New Sidebar
  const [menuItems, setMenuItems]: any = React.useState(otherLinks);
  const [selectedMenu, setSelectedMenu]: any = React.useState(null);

  useEffect(() => {
    if (profile && profile?.country === "South Africa") {
      setMenuItems(saLinks);
    }
  }, [profile]);

  const setIds = async (arr: any, id: any = null) => {
    let counter = 1;
    arr.forEach((item: any) => {
      let newId = id ? String(id) + "." + String(counter) : String(counter);
      item.id = newId;
      if (item.children) {
        setIds(item.children, newId);
      }
      counter++;
    });
  };

  const addIds = async () => {
    let i = menuItems;
    await setIds(i);
    await setMenuItems(i);

    // if (currentPage) {
    //   let i = _find(menuItems, "id", currentPage);

    //   if (!i?.children) {
    //     i = _find(menuItems, "id", getMenuParent(i.id));
    //   }

    //   setSelectedMenu({
    //     parentId: getMenuParent(i.id),
    //     items: i,
    //   });
    // }
  };

  React.useEffect(() => {
    addIds();

    // setMenuItems(topLinks);
    // console.log("topLinks", topLinks);
  }, [menuItems]);

  React.useEffect(() => {
    //  setMenuItems(setIds(menuItems));

    // setMenuItems(topLinks);
    // console.log("topLinks", topLinks);
    if (currentPage) {
      // console.log("currentPage", currentPage);
      // let i = _find(menuItems, "id", currentPage);
      // if (!i?.children) {
      //   i = _find(menuItems, "id", getMenuParent(i.id));
      // }
      // setSelectedMenu({
      //   parentId: getMenuParent(i.id),
      //   items: i,
      // });
    }
  }, [currentPage]);

  const gotoPage = (page: string) => {
    navigate(page);

    if (mobileMenuOpen) dispatch(toggleMobileMenu());
  };

  const [showConfirmDialog, setShowConfirmDialog] = React.useState<boolean>(false);

  return (
    <aside className={`${mobileMenuOpen ? "w-full" : "w-72"}`}>
      <div className="flex flex-col min-h-screen overflow-y-auto bg-gray-50 dark:bg-gray-800">
        <div className="bg-primary dark:bg-gray-100 ">
          <div className="relative flex items-center justify-center p-10">
            <img
              alt="Access Forex"
              src={require(`../../assets/new-logo-${theme}.png`)}
              className="max-w-100% w-32 mb-4"
            />
            {environment !== "live" ? (
              <span className="absolute font-bold top-2 text-secondary dark:text-primary text-1xl">
                {useLocalhost ? "LOCALHOST" : environment === "test" ? "TEST" : "STAGING"} ENVIRONMENT
              </span>
            ) : null}
            {mobileMenuOpen ? (
              <span
                onClick={() => dispatch(toggleMobileMenu())}
                className="absolute top-2 right-2 text-primary text-sm font-bold cursor-pointer bg-white p-2.5 py-1 rounded-full"
              >
                Close
              </span>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center p-4 m-4 -mt-8 bg-white rounded-lg shadow-lg dark:bg-primary">
          {dashboardLoading ? (
            <div className="flex flex-col items-center justify-center">
              <svg
                className="w-10 h-10 text-gray-200 dark:text-gray-700"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div className="w-24 h-2 my-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
          ) : (
            <>
              {profile ? (
                <>
                  <p className="font-bold text-center text-primary font-1xl dark:text-secondary">
                    {profile.fname} {profile.lname}
                  </p>
                  <p className={`text-gray-600 dark:text-gray-300 font-medium text-center`}>{profile.account_level}</p>
                </>
              ) : null}

              {branch ? (
                <div className="flex items-center justify-center px-3 py-1 my-2 text-center rounded-md bg-secondary text-primary">
                  {branch}
                </div>
              ) : null}
            </>
          )}
        </div>
        <div className="flex flex-col justify-between flex-grow">
          <div className="relative justify-start w-full p-2">
            {/* <Search placeholder="Search" darker /> */}
            {selectedMenu ? (
              <>
                {selectedMenu.items?.children ? (
                  <RenderMenuChildren
                    menuItems={menuItems}
                    selectedMenu={selectedMenu}
                    children={selectedMenu.items.children}
                    setSelectedMenu={(e: any) => setSelectedMenu(e)}
                  />
                ) : null}
              </>
            ) : null}
            {!selectedMenu ? (
              <div className="flex flex-col gap-2 p-2">
                {menuItems.map((link: any, key: number) => {
                  return (
                    <motion.span
                      initial={{ x: "-50%", opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.2, ease: "easeOut" }}
                      key={key}
                      className="flex items-center justify-between p-2 text-base transition-all duration-100 ease-in-out rounded-lg cursor-pointer delay-50 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
                      onClick={() =>
                        link?.children
                          ? setSelectedMenu({ parentId: null, items: link })
                          : link?.url
                          ? gotoPage(link.url)
                          : null
                      }
                    >
                      {link.title}{" "}
                      <IoChevronForwardSharp className="text-xl font-bold text-primary dark:text-gray-300" />
                    </motion.span>
                  );
                })}
              </div>
            ) : null}
          </div>
          <ul className="p-2 space-y-2">
            <li>
              <span className="flex items-center justify-between p-2 text-base font-normal text-gray-900 rounded-lg cursor-pointer dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">
                <span className="flex flex-row group" onClick={() => setShowConfirmDialog(true)}>
                  {/* <span className="flex flex-row group" onClick={() => dispatch(setShowConfirmationDialog(true))}> */}
                  <svg
                    className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap group-hover:text-secondary">Sign Out</span>
                </span>
                {/* <ThemeToggle /> */}
              </span>
            </li>
          </ul>
        </div>

        <p className="text-primary dark:text-gray-300 text-[12px] m-4">
          Copyright © {new Date().getFullYear()} • Access Forex • <span className="font-bold">v{app_version}</span>
        </p>
      </div>
      <ConfirmationDialog
        show={showConfirmDialog}
        onCancellation={() => setShowConfirmDialog(false)}
        title="Are you sure you want to logout?"
        yes="Yes, Logout!"
        onConfirmation={() => dispatch(restartAuth(""))}
      />
    </aside>
  );
}
